.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.table-block {
  height: 55%;
  box-shadow: inset 0 8px 8px -8px #696868;
  overflow: scroll;
  // overflow-x: scroll;
}

#tableId {
  display: inline-table;
}
.table-fixed {
  font-size: 14px;
}

.table-menue {
  position: relative;
}

.left-table-menue {
  margin-top: 10px;
  float: left;
  display: inline;
}

#right-btn-group {
  margin-right: 0rem !important;
}
.action-icon {
  margin: 2px;
  cursor: pointer;
}

.details-heading {
  font-weight: bold;
}

.cards {
  line-height: 0%;
}

.details-para {
  line-height: 1;
}

.icon-white {
  color: white;
}

.sort-icon {
  margin-top: 4px;
  float: right;
}

.logout {
  color: white;
  float: right;
}

.modal-content {
  margin-left: -40% !important;
  width: 160% !important;
}

.identityMap {
  height: 500px;
  width: 765px;
}

.listItem {
  color: #1890ff;
  padding: 5px;
  cursor: pointer;

  &:hover {
    color: #40a9ff;
  }
}

.marker-cluster-small {
  background-color: rgba(218, 94, 94, 0.6);
}
.marker-cluster-small div {
  background-color: rgba(226, 36, 36, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 30px;
}

.centerText {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#dropdown-export-data {
  border-radius: 0;
}

#dropdown-column-filter {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.wmsLayer {
  cursor: pointer; /* or any other cursor: https://developer.mozilla.org/en-US/docs/Web/CSS/cursor */
}

//react tour
.reactour {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}

.tour-content {
  padding-right: 10px;
}

.css-typing p {
  border-right: 0.15em solid orange;
  font-family: "Courier";
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.css-typing p:nth-child(1) {
  width: 10em;
  -webkit-animation: type 2s steps(40, end);
  animation: type 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(2) {
  width: 11em;
  opacity: 0;
  -webkit-animation: type2 2s steps(40, end);
  animation: type2 2s steps(40, end);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(3) {
  width: 4em;
  opacity: 0;
  -webkit-animation: type3 1s steps(20, end),
    blink 0.5s step-end infinite alternate;
  animation: type3 1s steps(20, end), blink 0.5s step-end infinite alternate;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}

.login__signin--heading {
  margin: 0em;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 34px;
  text-align: center;
  font-weight: bold;
  // font-style: italic;
}
